<template>
  <div class="inner-section">
  <card></card>
  <body-card>
    <!-- <template v-slot:headerTitle>
      <b-button variant="primary" size="sm" @click="pdfExport" class="mr-2">
        <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
      </b-button>
    </template> -->
    <template v-slot:headerAction>
      <router-link to="attendance" class="btn-add mr-2"><i class="ri-arrow-left-line"></i> {{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</router-link>
      <b-button variant="primary" size="sm" @click="pdfExport" class="mr-2">
        <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
      </b-button>
    </template>
    <template v-slot:body>
      <b-row v-if="formData.org_id">
        <b-col>
          <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="formData.org_id">
            {{ $t('elearning_tim.attendance') + ' ' + $t('globalTrans.details') }}
          </list-report-head>
        </b-col>
      </b-row>
      <b-overlay :show="loading">
        <b-row v-if="details.length > 0">
            <b-col lg="12" sm="12">
              <b-table-simple bordered>
                <b-tr>
                  <b-th style="width:18%;" scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.fiscal_year') }}</b-th>
                  <b-td style="width:32%%;" class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</b-td>
                  <b-th style="width:18%;" scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.organization') }}</b-th>
                  <b-td style="width:32%;" class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('externalTraining.circular_memo_no') }}</b-th>
                  <b-td class="pt-2 pb-2 text-left bg-light">{{ formData.circular_memo_no }}</b-td>
                  <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.training_type') }}</b-th>
                  <b-td  class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</b-td>
                </b-tr>
                <b-tr>
                  <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.training_title') }}</b-th>
                  <b-td  class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</b-td>
                  <b-th scope="row" class="pt-2 pb-2 text-left bg-light">{{ $t('elearning_config.batch') }}</b-th>
                  <b-td  class="pt-2 pb-2 text-left bg-light">{{ $i18n.locale === 'bn' ? formData.batch_bn : formData.batch }}</b-td>
                </b-tr>
              </b-table-simple>
            </b-col>
        </b-row>
        <b-row>
          <b-col lg="2" sm="2" v-for="(info, index) in dateList" :key="index">
            <b-form-checkbox
            :id="'checkbox-' + info.text"
            v-model="info.checked"
            :name="'checkbox-' + info.text"
            value=2
            unchecked-value=1
            @change="checkCount(info)"
            >
            {{info.text | dateFormat}}
            </b-form-checkbox>
          </b-col>
          <b-col class="mt-2">
            <body-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_tim.attendance')}} {{$t('globalTrans.list')}}</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <div class="col-md-12">
                    <b-table-simple hover bordered responsive  v-if="dateList.filter(item1 => item1.checked === '2').length>0">
                      <b-tr style="background: #f3f3f3">
                        <b-th rowspan="2" class="pt-2 pb-2 text-center" style="vertical-align:middle">{{$t('globalTrans.sl_no')}}</b-th>
                        <b-th rowspan="2" class="pt-2 pb-2" style="vertical-align:middle">{{$t('elearning_tim.trainee')}}</b-th>
                        <b-th rowspan="2" class="pt-2 pb-2 text-center" style="vertical-align:middle">{{$t('globalTrans.designation')}}</b-th>
                        <b-th rowspan="2" class="pt-2 pb-2 " style="vertical-align:middle">{{$t('globalTrans.mobile')}}</b-th>
                        <b-th style="width:10%" class="pt-2 pb-2 text-center" :colspan="dateList.filter(item1 => item1.checked === '2').length * 2">{{ $t('globalTrans.date') }}</b-th>
                      </b-tr>
                      <b-tr>
                        <slot v-for="(field) in dateList.filter(item1 => item1.checked === '2')">
                          <b-th class="pt-2 pb-2 text-center">
                            {{ field.text | dateFormat }}
                          </b-th>
                        </slot>
                      </b-tr>
                      <b-tr v-for="(train,index) in details" :key="index">
                        <b-td class="text-center">{{$n(index+1)}}</b-td>
                        <b-td>
                          {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                        </b-td>
                        <b-td class="text-center">
                          {{ ($i18n.locale==='bn') ? train.designation_bn : train.designation_en }}
                        </b-td>
                        <b-td>
                          {{ train.mobile }}
                          <small v-if="detailsErrors && detailsErrors['details.' + index + '.mobile']" class="text-danger">
                            {{ detailsErrors['details.'+index+'.mobile'] }}
                          </small>
                        </b-td>
                        <slot v-for="field in dateList.filter(item1 => item1.checked === '2')">
                          <b-td class="text-center">
                            {{ attendenInfo(train, field.text) }}
                            <!-- <slot v-if="details[index].dateList[index1].morning">
                                {{$t('elearning_tim.present')}}
                            </slot>
                            <slot v-else>
                                {{$t('elearning_tim.absent')}}
                            </slot> -->
                          </b-td>
                        </slot>
                      </b-tr>
                      <template v-if="details.length === 0">
                        <tr>
                          <th :colspan="dateList.length" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                        </tr>
                      </template>
                    </b-table-simple>
                  </div>
                </b-row>
              </template>
            </body-card>
          </b-col>
        </b-row>
      </b-overlay>
    </template>
</body-card>
<!-- <pre>{{ details }}</pre> -->
</div>
</template>
<script>
// import { ValidationObserver } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { circularPublication, batchWiseApplicationList } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import flatpickr from 'flatpickr'
import ExportPdf from './export_pdf_attendence_details'

export default {
  props: ['id'],
  components: {
    // ValidationObserver,
    ListReportHead
  },
  created () {
    this.getCircularMemoNo(this.$route.query.circularMemoNo)
    this.circularWiseAttendance()
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: true,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: this.$route.query.circularMemoNo,
        batch_id: this.$route.query.batchId,
        org: '',
        org_bn: '',
        fiscal_year: '',
        fiscal_year_bn: '',
        training_type: '',
        training_type_bn: '',
        batch: '',
        batch_bn: '',
        training_title: '',
        training_title_bn: '',
        training_date: '',
        trainer_id: 0,
        sort_status: 2,
        training_start_date: '',
        training_end_date: '',
        details: []
      },
      trainingCalendar: {
        training_start_date: '',
        training_end_date: ''
      },
      details: [],
      detailsErrors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      circularWiseAttendanceList: [],
      detailErrors: [],
      dateList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  watch: {
  },
  methods: {
    attendenInfo (item, date) {
      const saveObj = this.circularWiseAttendanceList.find(circularWiseAttendance => circularWiseAttendance.training_application_id === item.id && circularWiseAttendance.training_date === date)
      if (typeof saveObj !== 'undefined') {
        return this.$i18n.locale === 'bn' ? 'উপস্থিতি' : 'Present'
      } else {
        return this.$i18n.locale === 'bn' ? 'অনুপস্থিত' : 'Absent'
      }
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tim.attendance') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', this.formData, reportTitle, this.formData, this, this.details, this.dateList.filter(item1 => item1.checked === '2'))
    },
    checkCount (item) {
      const total = this.dateList.filter(item1 => item1.checked === '2')
      if (total.length > 6) {
        this.$toast.error({
          title: 'Error',
          message: this.$i18n.locale === 'bn' ? 'সর্বোচ্চ ছয় তারিখ যোগ করা যাবে!' : 'Maximum Six dates can be added!'
        })
        item.checked = false
        item.checAll = false
      }
    },
    checkData (item) {
        this.details.forEach((detail, detailIndex) => {
            detail.dateList.forEach((dateList, dateListIndex) => {
                if (dateList.text === item.text) {
                    if (item.checkAll === '2') {
                        dateList.checkData = true
                    } else {
                        dateList.checkData = false
                    }
                }
            })
        })
    },
    async circularWiseAttendance () {
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, batchWiseApplicationList, this.formData)
      if (result.success) {
        const listData = result.aryRange.map(item => {
          const updateData = {}
          updateData.checked = false
          updateData.morning = false
          updateData.text = item
          return Object.assign({}, updateData)
        })
        this.dateList = listData
        this.circularWiseAttendanceList = result.data
        this.details = result.trainingData
        this.loading = false
      } else {
        this.circularWiseAttendanceList = []
        this.details = []
        this.dateList = []
        this.loading = false
      }
    },
    async getCircularMemoNo (circularMemoNo) {
            this.load = true
            const params = {
              circular_memo_no: circularMemoNo,
              table: 'iab_circular_publications'
            }
            const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
                this.lcErrorMsg = ''
                const trData = result.data
                this.formData.org_id = trData.org_id
                const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(trData.org_id))
                if (typeof orgObj !== 'undefined') {
                    this.formData.org = orgObj.text_en
                    this.formData.org_bn = orgObj.text_bn
                } else {
                    this.formData.org = ''
                    this.formData.org_bn = ''
                }

                const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(trData.office_id))
                if (typeof officeObj !== 'undefined') {
                    this.formData.office = officeObj.text_en
                    this.formData.office_bn = officeObj.text_bn
                } else {
                    this.formData.office = ''
                    this.formData.office_bn = ''
                }

                const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(trData.fiscal_year_id))
                if (typeof fiscalYearObj !== 'undefined') {
                    this.formData.fiscal_year = fiscalYearObj.text_en
                    this.formData.fiscal_year_bn = fiscalYearObj.text_bn
                } else {
                    this.formData.fiscal_year = ''
                    this.formData.fiscal_year_bn = ''
                }
                const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(trData.training_title_id))
                if (typeof trainingTitleObj !== 'undefined') {
                    this.formData.training_title = trainingTitleObj.text_en
                    this.formData.training_title_bn = trainingTitleObj.text_bn
                } else {
                    this.formData.training_title = ''
                    this.formData.training_title_bn = ''
                }
                const OfficeTypeListObj = this.$store.state.CommonService.commonObj.officeTypeList.find(item1 => item1.value === parseInt(trData.office_type_id))
                if (typeof OfficeTypeListObj !== 'undefined') {
                    this.formData.office_type = OfficeTypeListObj.text_en
                    this.formData.office_type_bn = OfficeTypeListObj.text_bn
                } else {
                    this.formData.office_type = ''
                    this.formData.office_type_bn = ''
                }
                const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(trData.training_type_id))
                if (typeof trainingTypeObj !== 'undefined') {
                    this.formData.training_type = trainingTypeObj.text_en
                    this.formData.training_type_bn = trainingTypeObj.text_bn
                } else {
                    this.formData.training_type = ''
                    this.formData.training_type_bn = ''
                }
                const batchObj = this.$store.state.TrainingElearning.commonObj.batchList.find(doc => doc.value === parseInt(this.formData.batch_id))
                if (typeof batchObj !== 'undefined') {
                    this.formData.batch = batchObj.text_en
                    this.formData.batch_bn = batchObj.text_bn
                } else {
                    this.formData.batch = ''
                    this.formData.batch_bn = ''
                }
                const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(trData.training_category_id))
                if (typeof trainingCategoryObj !== 'undefined') {
                    this.formData.training_category = trainingCategoryObj.text_en
                    this.formData.training_category_bn = trainingCategoryObj.text_bn
                } else {
                    this.formData.training_category = ''
                    this.formData.training_category_bn = ''
                }
                const designationObj = this.$store.state.CommonService.commonObj.designationList.find(doc => doc.value === parseInt(trData.designation_id))
                if (typeof designationObj !== 'undefined') {
                    this.formData.designation_name = designationObj.text_en
                    this.formData.designation_name_bn = designationObj.text_bn
                } else {
                    this.formData.designation_name = ''
                    this.formData.designation_name_bn = ''
                }
                this.load = false
            this.load = false
    }
  }
}
</script>
