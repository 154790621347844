import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/utils/fliter'
// import { dateFormat, time12FormateTraining } from '@/utils/fliter'
const exportPdfDetails = async (baseUrl, uri, formData, reportTitle, data, vm, detailsData, dateList) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        pdfMake.fonts = {
          Kalpurush: {
            normal: 'Kalpurush.ttf',
            bold: 'Kalpurush.ttf',
            italics: 'Kalpurush.ttf',
            bolditalics: 'Kalpurush.ttf'
          }
        }
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
          pdfMake.fonts = {
            Roboto: {
              normal: 'Roboto-Regular.ttf',
              bold: 'Roboto-Medium.ttf',
              italics: 'Roboto-Italic.ttf',
              bolditalics: 'Roboto-MediumItalic.ttf'
            }
          }
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, formData.org_id)
        const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const bodySeperate = [
          [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' },
            { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' }
          ]
        ]
        bodySeperate.push([
          { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: data.circular_memo_no, style: 'td', alignment: 'left' },
          { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' }
        ])
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.batch'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.batch_bn : data.batch, style: 'td', alignment: 'left' }
          ]
        )
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['15%', '2%', '33%', '15%', '2%', '33%'],
                body: bodySeperate
            },
            layout: {
              hLineWidth: function (i, node) {
                  return 0
              },
              vLineWidth: function (i, node) {
                  return 0
              }
            }
        })
        pdfContent.push({ text: vm.$t('elearning_tim.attendance') + ' ' + vm.$t('globalTrans.list'), style: 'hh', alignment: 'center' })
        const firstHeader = [
            { text: vm.$t('globalTrans.sl_no'), style: 'th1', rowSpan: 2, alignment: 'center' },
            { text: vm.$t('elearning_tim.trainee'), style: 'th1', rowSpan: 2, alignment: 'center' },
            { text: vm.$t('globalTrans.designation'), style: 'th', rowSpan: 2, alignment: 'center' },
            { text: vm.$t('globalTrans.mobile'), style: 'th1', rowSpan: 2, alignment: 'center' },
            { text: vm.$t('globalTrans.date'), style: 'th1', colSpan: dateList.length, alignment: 'center' }
        ]
        if (dateList.length > 1) {
          for (let i = 1; i < dateList.length; i++) {
            if (i === 1) {
              firstHeader.push(
               {}
              )
            } else {
              firstHeader.push(
                {}
              )
            }
          }
        }
        const allRows = [
          firstHeader
        ]
        const secondHeader = [
          {},
          {},
          {},
          {}
        ]
        const widthList = ['5%', '10%', '10%', '10%']
        dateList.forEach(dateItem => {
          secondHeader.push(
            { text: (dateItem) ? dateFormat(dateItem.text) : '', style: 'th1', alignment: 'center', bold: true }
          )
          widthList.push('*')
        })
        allRows.push(secondHeader)
        // table body
        detailsData.filter((data, optionIndex) => {
          const rowItem = [
            { text: vm.$n(optionIndex + 1), style: 'td1', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.name_bn : data.name, style: 'td1', alignment: 'center' },
            { text: (vm.$i18n.locale === 'bn' ? data.designation_bn : data.designation_en), style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.mobile : data.mobile, style: 'td1', alignment: 'center' }
          ]
          dateList.forEach((field, index1) => {
            const morningPresent = vm.attendenInfo(data, field.text)
            rowItem.push(
              { text: morningPresent, alignment: 'center', style: 'td1' }
            )
          })
          allRows.push(rowItem)
        })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: widthList,
            body: allRows
          }
        })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          th1: {
            fontSize: (i18n === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          td1: {
            fontSize: (i18n === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          header: {
              fontSize: 14,
              bold: true,
              alignment: 'center',
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 12,
              bold: true,
              alignment: 'center',
               margin: [0, -25, 0, 0]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
